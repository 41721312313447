import React, { useState, useEffect } from 'react'; //we already import useState and useEffect that we will use later
import {isMobile, BrowserView} from 'react-device-detect';

import EggCursorEgg from "../images/cursor_egg.inline.svg";
import EggCursorYolk from "../images/cursor_yolk.inline.svg";


const EggCursor = () => {
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [click, setClick] = useState(false);

    useEffect(() => {
      const addEventListeners = () => {
        document.addEventListener('mousemove',mMove);
        document.addEventListener('mousedown', mDown);
        document.addEventListener('mouseup', mUp);
      };
  
      const removeEventListeners = () => {
        document.removeEventListener('mousemove', mMove);
        document.removeEventListener('mousedown', mDown);
        document.removeEventListener('mouseup', mUp);
      };
  
      const mMove = (el) => {
        setPosition({ x: el.clientX, y: el.clientY });
      };

      const mDown = () => {
        setClick(true);
      };
  
      const mUp = () => {
        setTimeout(() => setClick(false), 1000)
        //setClick(false);
      };
    
      addEventListeners();
      return () => removeEventListeners();
    }, []);
  
  
    return (
      <div
        className={'eggcursor'}
        style={{
          left: `${position.x}px`,
          top: `${position.y}px`,
          display: `${(isMobile ? 'none' : 'block')}`,
        }}
      >
          <EggCursorEgg
            alt="Sample Report" 
            width={'100%'}
            height={'100%'}
            className="position-static"
            style={{
              display: `${(click ? 'none' : 'block')}`,
            }}
          />
          <EggCursorYolk
            alt="Sample Report"
            width={'100%'}
            height={'100%'}
            className="position-static"
            style={{
              display: `${(click ? 'block' : 'none')}`,
            }}
          />
      </div>
    );
};

export default EggCursor;